import * as actionTypes from "./actionTypes";

export const sockJsConnect = () => {
  return {
    type: actionTypes.SOCKJS_CONNECT,
  };
};

export const sockJsSubscribe = (payload) => {
  return {
    type: actionTypes.SOCKJS_SUBSCRIBE,
    payload,
  };
};

export const sockJsUnsubscribe = () => {
  return {
    type: actionTypes.SOCKJS_UNSUBSCRIBE,
  };
};

export const webSocketConnectedCheck = (checkValue) => {
  return {
    type: actionTypes.WEBSOCKET_CONNECTION_CHECK,
    isConnectedCheck: checkValue,
  };
};
