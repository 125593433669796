import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  socketMsg: null,
  isConnectedCheck: "true",
};

const socketUnsubscribe = (state, action) => {
  return Object.assign({}, state, {
    socketMsg: null,
  });
};

const socketSubscribe = (state, action) => {
  return Object.assign({}, state, {
    socketMsg: action.payload,
  });
};

const webSocketConnectedCheck = (state, action) => {
  return Object.assign({}, state, {
    isConnectedCheck: action.isConnectedCheck,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SOCKJS_SUBSCRIBE:
      return socketSubscribe(state, action);
    case actionTypes.SOCKJS_UNSUBSCRIBE:
      return socketUnsubscribe(state, action);
    case actionTypes.WEBSOCKET_CONNECTION_CHECK:
      return webSocketConnectedCheck(state, action);
    default:
      return state;
  }
};

export default reducer;
