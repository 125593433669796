import axios from "../../axios";
import * as actionTypes from "./actionTypes";

export const userList = (userList) => {
  return {
    type: actionTypes.USER_LIST,
    userList: userList,
  };
};

export const fetchUserList = (xApiKey) => {
  return (dispatch) => {
    axios
      .get("/user-api/user/", {
        headers: { "x-api-key": xApiKey },
      })
      .then((response) => {
        dispatch(userList(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };
};
