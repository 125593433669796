import * as actionTypes from "../actions/actionTypes";

const initialState = {
  userList: [],
};

const userListSuccess = (state, action) => {
  return Object.assign({}, state, {
    userList: action.userList,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LIST:
      return userListSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
