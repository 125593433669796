import * as actionTypes from "../actions/actionTypes";
import { Observable } from "rxjs";
import { sockJsSubscribe, webSocketConnectedCheck } from "../actions/websocket";
import SocketConnection from "./socketConnection";
import { map, filter } from "rxjs/operators";

const socketMiddleware = (baseURL) => {
  var url = `${process.env.REACT_APP_BASE_URL}lsm-websocket`;
  let client = null;
  let observable;
  let dispatch = null;

  const handleCheckValue = (value) => {
    dispatch(webSocketConnectedCheck(value));
  };

  const handleMessage = (payload) => {
    observable = new Observable((subscriber) => {
      subscriber.next(payload);
    });

    observable = observable.pipe(map((message) => JSON.parse(message)));

    const liveShotObservable = observable.pipe(
      filter(
        (message) =>
          message.type === "ABSTRACTLIVESHOT" ||
          message.type === "LIVESHOT" ||
          message.type === "LIVESHOTSIGNAL" ||
          message.type === "CANVAS"
      )
    );

    liveShotObservable.subscribe((msg) => {
      dispatch(sockJsSubscribe(msg));
    });

    dispatch(sockJsSubscribe(null)); // send extra null notification as end frame
  };

  const middleware = (store) => (next) => (action) => {
    dispatch = store.dispatch;
    switch (action.type) {
      case actionTypes.SOCKJS_CONNECT:
        client = new SocketConnection(
          url,
          "/topic/lsm",
          (msg) => {
            handleMessage(msg);
          },
          (isValue) => {
            handleCheckValue(isValue);
          }
        );
        client.subscribe();
        break;
      case actionTypes.SOCKJS_UNSUBSCRIBE:
        client.unsubscribe();
        break;
      default:
        break;
    }

    return next(action);
  };

  return middleware;
};

export default socketMiddleware;
