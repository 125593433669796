export const convertTimeFormat = (time) => {
  let hours = time.toDate().getHours();
  let minutes = time.toDate().getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
};

export const DateFormatterTime = (date) => {
  if (date === null) return;
  let Time = null;
  Time = new Date();
  let dateWithHourMinutes = date.split(":");
  Time.setHours(dateWithHourMinutes[0]);
  Time.setMinutes(dateWithHourMinutes[1]);
  let valueHours = Time.toLocaleTimeString(Time, {
    hour: "numeric",
    hour12: false,
  });
  let valueMinutes = Time.toLocaleTimeString(Time, {
    minute: "numeric",
    hour12: false,
  });
  let valueSeconds = 0;
  var target = +valueHours * 60 * 60 + +valueMinutes * 60 + +valueSeconds;
  return target;
};
