export const FETCH_ARRIVALSBOARD_DATA = "FETCH_ARRIVALSBOARD_DATA";
export const ADD_TALLY_INFO = "ADD_TALLY_INFO";

export const USER_LIST = "USER_LIST";

export const SOCKJS_SUBSCRIBE = "SOCKJS_SUBSCRIBE";
export const SOCKJS_UNSUBSCRIBE = "SOCKJS_UNSUBSCRIBE";
export const SOCKJS_CONNECT = "SOCKJS_CONNECT";
export const FETCH_CONTROLROOM_LIST_SUCCESS = "FETCH_CONTROLROOM_LIST_SUCCESS";
export const WEBSOCKET_CONNECTION_CHECK = "WEBSOCKET_CONNECTION_CHECK";
