import * as actionTypes from "../actions/actionTypes";

const initialState = {
  arrivalsBoardInfo: [],
  controlRoomList: [],
  ifbTally: [],
  plTally: [],
  phoTally: [],
};

const arrivalsBoardSuccess = (state, action) => {
  return Object.assign({}, state, {
    arrivalsBoardInfo: action.arrivalsBoardInfo,
    lastUpdated: action.lastUpdated,
  });
};

const fetchControlRoomListSuccess = (state, action) => {
  return Object.assign({}, state, {
    controlRoomList: action.controlRoomList,
  });
};

const addTallyInfoSuccess = (state, action) => {
  return Object.assign({}, state, {
    ifbTally: action.ifbData,
    plTally: action.plData,
    phoTally: action.phoData,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ARRIVALSBOARD_DATA:
      return arrivalsBoardSuccess(state, action);
    case actionTypes.FETCH_CONTROLROOM_LIST_SUCCESS:
      return fetchControlRoomListSuccess(state, action);
    case actionTypes.ADD_TALLY_INFO:
      return addTallyInfoSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
